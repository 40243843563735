<template lang="">
  <div class="d-flex-center gap-1">
    <BButton
      variant="flat-success"
      class="rounded-circle p-50"
      @click="number > 0 ? $emit('changeNumber', number - 1) : null"
    >
      <IAmIcon
        icon="minusCircleOutline"
        size="20"
      />
    </BButton>
    <BFormInput
      v-remove-non-numeric-chars.allNumber
      :value="number"
      maxlength="2"
      style="width: 50px !important;"
      class="border-transparent fw-700 text-18px fit-content"
      @input="val => changeNumber(val)"
    />
    <BButton
      variant="flat-success"
      class="rounded-circle p-50"
      @click="$emit('changeNumber', number + 1)"
    >
      <IAmIcon
        icon="plusCircleOutline"
        size="20"
      />
    </BButton>
  </div>
</template>
<script>
import { BButton, BFormInput } from 'bootstrap-vue'
import { isNaN } from 'lodash-es'

export default {
  components: { BButton, BFormInput },
  props: {
    number: { type: Number, default: 0 },
  },
  setup(props, { emit }) {
    function changeNumber(val) {
      if (isNaN(Number(val))) return
      emit('changeNumber', Number(val))
    }
    return { changeNumber }
  },
}
</script>
<style lang="">

</style>
