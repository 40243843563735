var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex-center gap-1"
  }, [_c('BButton', {
    staticClass: "rounded-circle p-50",
    attrs: {
      "variant": "flat-success"
    },
    on: {
      "click": function click($event) {
        _vm.number > 0 ? _vm.$emit('changeNumber', _vm.number - 1) : null;
      }
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "minusCircleOutline",
      "size": "20"
    }
  })], 1), _c('BFormInput', {
    directives: [{
      name: "remove-non-numeric-chars",
      rawName: "v-remove-non-numeric-chars.allNumber",
      modifiers: {
        "allNumber": true
      }
    }],
    staticClass: "border-transparent fw-700 text-18px fit-content",
    staticStyle: {
      "width": "50px !important"
    },
    attrs: {
      "value": _vm.number,
      "maxlength": "2"
    },
    on: {
      "input": function input(val) {
        return _vm.changeNumber(val);
      }
    }
  }), _c('BButton', {
    staticClass: "rounded-circle p-50",
    attrs: {
      "variant": "flat-success"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('changeNumber', _vm.number + 1);
      }
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "plusCircleOutline",
      "size": "20"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }